import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home"
import Support from "./Support";
// import Index from "./pages/articles/Index";
import MarketingBasics from "./pages/articles/MarketingBasics";
import TargetSetting from "./pages/articles/TargetSetting";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/support" element={<Support/>}/>
        {/* <Route path="/articles" element={<Index />} /> */}
        <Route path="/articles/marketing-basics" element={<MarketingBasics />} />
        <Route path="/articles/target-setting" element={<TargetSetting />} />
      </Routes>
    </Router>
  );
};

export default App;
