import React, { useEffect } from "react";
import { Box, Typography, Container, List, ListItem, Link, Button } from "@mui/material";

const ArticleLayout = ({ title, description, sections, ctaLink, ctaText }) => {
  useEffect(() => {
    document.title = title;

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", description);
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.setAttribute("name", "description");
      newMetaDescription.setAttribute("content", description);
      document.head.appendChild(newMetaDescription);
    }
  }, [title, description]);

  return (
    <Container
      maxWidth="md"
      sx={{
        marginTop: 0,
        backgroundColor: "#fffae6",
        padding: { xs: 2, sm: 3 },
        borderRadius: 2,
      }}
    >
      {/* 記事タイトル */}
      <Box sx={{ marginBottom: 4 }}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            fontSize: { xs: "1.5rem", sm: "2rem" },
            lineHeight: 1.4,
            fontWeight: "bold",
            borderBottom: "2px solid #000",
            paddingBottom: 0.5,
          }}
        >
          {title}
        </Typography>
      </Box>

      {/* 目次 */}
      <Box
        sx={{
          border: "1px solid #aaa",
          borderRadius: 1,
          padding: 2,
          marginBottom: 3,
          marginTop: 3,
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: { xs: "1rem", sm: "1.2rem" },
            fontWeight: "bold",
            marginBottom: 1,
          }}
        >
          目次
        </Typography>
        <List
          sx={{
            padding: 0,
            "& .MuiListItem-root": {
              paddingTop: 0.5,
              paddingBottom: 0.5,
            },
          }}
        >
          {sections.map((section, index) => (
            <ListItem key={index}>
              <Link href={`#section${index + 1}`} underline="hover" color="inherit">
                {section.title}
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>

      {/* セクション */}
      {sections.map((section, index) => (
        <Box id={`section${index + 1}`} key={index} sx={{ marginBottom: 4 }}>
            <Box
            sx={{
                backgroundColor: "#f9db00",
                padding: "8px 16px",
                borderRadius: 0,
                width: "calc(100% - 32px)",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 2,
                fontWeight: "bold",
            }}
            >
                <Typography
                    variant="h6"
                    component="h2"
                    sx={{
                    fontSize: { xs: "1.2rem", sm: "1.4rem" },
                    fontWeight: "bold",
                    color: "#000",
                    }}
                >
                    {section.title}
                </Typography>
            </Box>
            {/* コンテンツをJSXまたはテキストで受け取る */}
            <Box sx={{ lineHeight: 1.8, marginTop: 2 }}>
                {section.content}
            </Box>
        </Box>
      ))}

      {/* CTA */}
      <Box
        sx={{
          fontSize: { xs: "0.9rem", sm: "1rem" },
          border: "1px solid #aaa",
          borderRadius: 1,
          padding: 2,
          marginBottom: 3,
          marginTop: 3,
          backgroundColor: "#fff",
        }}
      >
        リピーターが増えれば、売上が安定化します。<br />
        ポイントカードアプリを導入して、お客様とのつながりを強化しませんか？
        <Box sx={{ marginTop: 2, textAlign: "center" }}>
          <Button
            variant="contained"
            href={ctaLink}
            sx={{
              backgroundColor: "#0066cc",
              color: "#fff",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#004c99",
              },
            }}
          >
            {ctaText}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ArticleLayout;
