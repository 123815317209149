import React, { useEffect } from "react";
import { Box, Typography, Container, List, ListItem, Link, Button } from "@mui/material";

function MarketingBasics() {
  useEffect(() => {
    document.title = "飲食店マーケティングの基本 | conekuta";

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "飲食店マーケティングの基本について学び、成功のための重要なポイントを押さえましょう。"
      );
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.setAttribute("name", "description");
      newMetaDescription.setAttribute(
        "content",
        "飲食店マーケティングの基本について学び、成功のための重要なポイントを押さえましょう。"
      );
      document.head.appendChild(newMetaDescription);
    }
  }, []);

  return (
    <Container
      maxWidth="md"
      sx={{
        marginTop: 0,
        backgroundColor: "#fffae6",
        padding: { xs: 2, sm: 3 },
        borderRadius: 2,
      }}
    >
      {/* 記事タイトル */}
      <Box sx={{ marginBottom: 4 }}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            fontSize: { xs: "1.5rem", sm: "2rem" },
            lineHeight: 1.4,
            fontWeight: "bold",
            borderBottom: "2px solid #000", // 黒い下線を追加
            paddingBottom: 0.5, // 下線と文字の間隔を調整
          }}
        >
          飲食店マーケティングの基本
        </Typography>
      </Box>

      {/* 目次 */}
      <Box
        sx={{
          border: "1px solid #aaa",
          borderRadius: 1,
          padding: 2,
          marginBottom: 3,
          marginTop: 3,
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: { xs: "1rem", sm: "1.2rem" },
            fontWeight: "bold",
            marginBottom: 1,
          }}
        >
          目次
        </Typography>
        <List
          sx={{
            padding: 0,
            "& .MuiListItem-root": {
              paddingTop: 0.5, // 1〜4の上下の余白を詰める
              paddingBottom: 0.5,
            },
          }}
        >
          <ListItem>
            <Link href="#section1" underline="hover" color="inherit">
              1. 飲食店マーケティングとは？
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#section2" underline="hover" color="inherit">
              2. 新規顧客とリピーターの違いを理解しよう
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#section3" underline="hover" color="inherit">
              3. 飲食店マーケティングの4つのポイント
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#summary" underline="hover" color="inherit">
              4. まとめ
            </Link>
          </ListItem>
        </List>
      </Box>

      {/* セクション1 */}
      <Box id="section1" sx={{ marginBottom: 4 }}>
        <Box
          sx={{
            backgroundColor: "#f9db00",
            padding: "8px 16px",
            borderRadius: 0,
            width: "calc(100% - 32px)",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 2,
            fontWeight: "bold",
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{
              fontSize: { xs: "1.2rem", sm: "1.4rem" },
              fontWeight: "bold",
              color: "#000",
            }}
          >
            1. 飲食店マーケティングとは？
          </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "0.9rem", sm: "1rem" },
            lineHeight: 1.8,
            marginTop: 2,
          }}
        >
          飲食店マーケティングとは、<b>お店を知ってもらい、顧客との関係を構築し、継続的に来店してもらうための戦略</b>
          を指します。ただ「おいしい料理を出す」だけでは、現代の競争の激しい飲食業界で生き残るのは難しいです。魅力的なサービスやメニューを提供するだけでなく、それをどうやって
          <b>伝えるか</b>が重要になります。
        </Typography>
      </Box>

      {/* セクション2 */}
      <Box id="section2" sx={{ marginBottom: 4 }}>
        <Box
          sx={{
            backgroundColor: "#f9db00",
            padding: "8px 16px",
            borderRadius: 0,
            width: "calc(100% - 32px)",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 2,
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{
              fontSize: { xs: "1.2rem", sm: "1.4rem" },
              fontWeight: "bold",
              color: "#000",
            }}
          >
            2. 「新規顧客」と「リピーター」の違いを理解しよう
          </Typography>
        </Box>
        <Typography
            variant="body1"
            sx={{
                fontSize: { xs: "0.9rem", sm: "1rem" },
                lineHeight: 1.8,
                marginTop: 2,
            }}
        >
            飲食店のマーケティングを考える際に、
            <b>「新規顧客」と「リピーター」へのアプローチは異なる</b>ということを理解する必要があります。それぞれの特徴と重要性を見てみましょう。
        </Typography>
        <Typography variant="body2" sx={{ marginTop: 2 }}>
            <b>新規顧客</b>：
        </Typography>
        <ul>
            <li>初めて来店するお客様で、まだお店のサービスや料理を体験していない人々。</li>
            <li>初回限定の割引キャンペーンやSNS広告が効果的です。</li>
        </ul>
        <Typography variant="body2" sx={{ marginTop: 2 }}>
            <b>リピーター</b>：
        </Typography>
        <ul>
            <li>既に来店したことがあるお客様。</li>
            <li>ポイントカードやアプリでの特典が来店頻度を高めます。</li>
        </ul>
      </Box>

      {/* セクション3 */}
      <Box id="section3" sx={{ marginBottom: 4 }}>
        <Box
          sx={{
            backgroundColor: "#f9db00",
            padding: "8px 16px",
            borderRadius: 0,
            width: "calc(100% - 32px)",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            marginBottom: 2,
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{
              fontSize: { xs: "1.2rem", sm: "1.4rem" },
              fontWeight: "bold",
              color: "#000",
            }}
          >
            3. 飲食店マーケティングの柱：4つのポイント
          </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "0.9rem", sm: "1rem" },
            lineHeight: 1.8,
            marginTop: 2,
          }}
        >
          飲食店のマーケティングを効果的に行うためには、以下の4つのポイントを押さえておきましょう。
        </Typography>
        <Typography variant="body2" sx={{ marginTop: 2 }}>
          <b>① ターゲットを明確にする:</b> 来店してほしいお客様像を具体化し、それに応じた施策を計画します。
        </Typography>
        <Typography variant="body2" sx={{ marginTop: 2 }}>
          <b>② 魅力を伝えるメッセージを作る:</b> USP（Unique Selling Proposition）を明確にし、お店の強みを一言で伝えます。
        </Typography>
        <Typography variant="body2" sx={{ marginTop: 2 }}>
          <b>③ オンラインとオフラインを活用する:</b> SNSや地域イベントを組み合わせて集客効果を最大化します。
        </Typography>
        <Typography variant="body2" sx={{ marginTop: 2 }}>
          <b>④ 効果を測定して改善する:</b> 来店数や売上データを分析し、施策を改善します。
        </Typography>
      </Box>

      {/* まとめ */}
      <Box id="summary" sx={{ marginBottom: 4 }}>
        <Box
          sx={{
            backgroundColor: "#f9db00",
            padding: "8px 16px",
            borderRadius: 0,
            width: "calc(100% - 32px)",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            marginBottom: 2,
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{
              fontSize: { xs: "1.2rem", sm: "1.4rem" },
              fontWeight: "bold",
              color: "#000",
            }}
          >
            4. まとめ
          </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "0.9rem", sm: "1rem" },
            lineHeight: 1.8,
            marginTop: 2,
          }}
        >
          飲食店のマーケティングは、一度行って終わりではありません。継続的に改善と工夫を重ねることで、顧客との関係を深め、売上を安定させることができます。
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "0.9rem", sm: "1rem" },
            marginTop: 2,
            textAlign: "center",
          }}
        >
          <Link href="/next-article" underline="hover">
            次回の記事では、「ターゲット顧客の設定方法について詳しく解説します。」
          </Link>
        </Typography>
        <Box
          sx={{
            fontSize: { xs: "0.9rem", sm: "1rem" },
            border: "1px solid #aaa",
            borderRadius: 1,
            padding: 2,
            marginBottom: 3,
            marginTop: 3,
            backgroundColor: "#fff",
          }}
        >
          リピーターが増えれば、売上が安定化します。<br />
          ポイントカードアプリを導入して、お客様とのつながりを強化しませんか？
        <Box sx={{ marginTop: 2, textAlign: "center" }}>
            <Button
                variant="contained"
                href="/cta"
                sx={{
                backgroundColor: "#0066cc", // 青系のボタン色
                color: "#fff",
                fontWeight: "bold",
                "&:hover": {
                    backgroundColor: "#004c99", // 青色の濃いホバー色
                },
                }}
            >
                詳しくはこちら
            </Button>
        </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default MarketingBasics;
