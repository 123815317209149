import React from "react";
import ArticleLayout from "../../components/layouts/ArticleLayout";

const TargetSetting = () => {
  const sections = [
    {
      title: "1. 飲食店マーケティングとは？",
      content: (
        <>
          <p>
            飲食店マーケティングとは、<b>お店を知ってもらい、顧客との関係を構築し、継続的に来店してもらうための戦略</b>
            を指します。ただ「おいしい料理を出す」だけでは、現代の競争の激しい飲食業界で生き残るのは難しいです。
          </p>
          <p>
            魅力的なサービスやメニューを提供するだけでなく、それをどうやって <b>伝えるか</b> が重要になります。
          </p>
        </>
      ),
    },
    {
      title: "2. 新規顧客とリピーターの違いを理解しよう",
      content: (
        <>
          <p>
            新規顧客とリピーターへのアプローチは異なります。それぞれの特徴を見てみましょう。
          </p>
          <ul>
            <li>
              <b>新規顧客:</b> 初回限定の割引キャンペーンやSNS広告で来店を促します。
            </li>
            <li>
              <b>リピーター:</b> ポイントカードやアプリを活用して再訪を促進します。
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "3. 飲食店マーケティングの柱：4つのポイント",
      content: (
        <>
          <p>飲食店マーケティングの重要な柱を以下に示します:</p>
          <ol>
            <li>ターゲット顧客を明確にする</li>
            <li>魅力的なメッセージを作成する</li>
            <li>オンラインとオフラインを活用する</li>
            <li>結果を測定し改善する</li>
          </ol>
        </>
      ),
    },
    {
      title: "4. まとめ",
      content: "マーケティングは一度行って終わりではなく、継続的な改善が必要です。",
    },
  ];

  return (
    <ArticleLayout
      title="ターゲット"
      description="ターゲットの決め方"
      sections={sections}
      ctaLink="/cta"
      ctaText="詳しくはこちら"
    />
  );
};

export default TargetSetting;
